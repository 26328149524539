import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Formik } from 'formik'
import throttle from 'lodash/throttle'

import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import Field from 'components/common/FormRenderer/Field'
import { format, parseISO } from 'date-fns'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import DataTable from 'components/common/DataTable'
import useClientCase from 'hooks/useClientCase'
import useAuth from 'hooks/context/useAuth'
import useUser from 'hooks/useUser'
import useCustomer from 'hooks/useCustomer'
import useTimeExpenseLog from 'hooks/useTImeExpenseLog'
import resolveErrorText from 'helpers/resolveErrorText'

const timeLogClientCaseParamsDefault = {
  limit: 5,
  offset: 0,
  sort: { client_case_id: false },
}

const timeLogFilterParamsDefault = {
  limit: 10,
  offset: 0,
  sort: {
    time_log_date: false,
  },
}

const minuteOptions = []
for (let i = 0; i < 60; i += 6) {
  minuteOptions[i / 6] = {
    display: `${i}`,
    value: i,
  }
}

const expenseLogClientCaseParamsDefault = {
  limit: 5,
  offset: 0,
  sort: { client_case_id: false },
}
const expenseLogFilterParamsDefault = {
  limit: 10,
  offset: 0,
  sort: {
    expense_log_date: false,
  },
}

function TimeExpenseLog() {
  const { clientCaseSearch } = useClientCase()
  const { timeLogSearch, timeLogCreate, expenseLogSearch, expenseLogCreate } =
    useTimeExpenseLog()
  const { customerSearch } = useCustomer()
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const { userSearch } = useUser()
  const [tabState, setTabState] = useState('timelogs')

  const formTimeLogRef = useRef(null)
  const formTimeLogFilterRef = useRef(null)
  const [users, setUsers] = useState([])
  const [userNameMap, setUserNameMap] = useState(null)
  const [timeLogCustomerInputLookupValue, setTimeLogCustomerInputLookupValue] =
    useState('')
  const [timeLogCustomerLookupValue, setTimeLogCustomerLookupValue] =
    useState('')
  const [timeLogCustomerOptions, setTimeLogCustomerOptions] = useState([])
  const [timeLogCustomerCaseOptions, setTimeLogCustomerCaseOptions] = useState(
    []
  )

  const [timeLogClientCaseParams, setTimeLogClientCaseParams] = useState(
    timeLogClientCaseParamsDefault
  )

  const [timeLogCustomerCasesHaveMore, setTimeLogCustomerCasesHaveMore] =
    useState(false)
  const [timeLogSelectedCaseID, setTimeLogSelectedCaseID] = useState(null)
  const [openTimeLogCreate, setOpenTimeLogCreate] = useState(false)

  const [timeLogFilterOpen, setTimeLogFilterOpen] = useState(false)

  const [timeLogFilterParams, setTimeLogFilterParams] = useState(
    timeLogFilterParamsDefault
  )
  const [timeLogs, setTimeLogs] = useState([])
  const [timeLogDisplay, setTimeLogDisplay] = useState([])
  const [timeLogsHaveMore, setTimeLogsHaveMore] = useState(false)

  const [userAdminOrStaff, setUserAdminOrStaff] = useState(null)

  const resetTimeLogForm = () => {
    setTimeLogSelectedCaseID(null)
    setTimeLogCustomerLookupValue('')
    setTimeLogCustomerInputLookupValue('')
    setTimeLogClientCaseParams(timeLogClientCaseParamsDefault)
    formTimeLogRef.current.resetForm()
  }

  const timeLogCreateFetchCustomers = useMemo(
    () =>
      throttle((request, callback) => {
        let customerNameSearch =
          typeof request === 'string' ? request : request.input
        customerSearch({
          customerName: customerNameSearch,
          limit: 10,
        }).then((results) => {
          callback(
            results
              ? results.map((customer) => {
                  return {
                    id: customer.customer_id,
                    display: `${customer.customer_first_name} ${customer.customer_last_name} <${customer.customer_email}>`,
                  }
                })
              : []
          )
        })
      }, 200),
    []
  )

  const timeLogCreateLoadInNewCustomerClientCases = (resp) => {
    let getNextParams = {
      ...timeLogClientCaseParams,
    }
    let clientCases = []
    if (resp?.client_cases) {
      if (resp.limit > 0 && resp.client_cases.length === resp.limit) {
        setTimeLogCustomerCasesHaveMore(true)
        getNextParams.offset += resp.limit
      } else {
        getNextParams.offset += resp.client_cases.length
        setTimeLogCustomerCasesHaveMore(false)
      }
      setTimeLogClientCaseParams(getNextParams)
      clientCases = resp.client_cases.map((clientCase) => {
        return {
          ID: (
            <FormControlLabel
              value={clientCase.client_case_id}
              control={<Radio />}
              label={clientCase.client_case_id}
            />
          ),
          'Due Date': format(
            parseISO(clientCase.client_case_due_date),
            'M/d/y'
          ),
          Status: clientCase.client_case_status,
          Created: format(
            parseISO(clientCase.client_case_created_datetime),
            'M/d/Y h:mm a'
          ),
        }
      })
      setTimeLogCustomerCaseOptions([
        ...timeLogCustomerCaseOptions,
        ...clientCases,
      ])
    } else {
      setTimeLogCustomerCasesHaveMore(false)
    }
  }

  const timeLogCreateLoadMoreClientCases = () => {
    clientCaseSearch({
      ...timeLogClientCaseParams,
      customer_id: timeLogCustomerLookupValue.id,
    }).then((resp) => {
      timeLogCreateLoadInNewCustomerClientCases(resp)
    })
  }
  useEffect(() => {
    if (!timeLogCustomerLookupValue?.id) {
      setTimeLogCustomerCaseOptions([])
      return
    }
    clientCaseSearch({
      ...timeLogClientCaseParams,
      customer_id: timeLogCustomerLookupValue.id,
    }).then((resp) => {
      timeLogCreateLoadInNewCustomerClientCases(resp)
      setTimeLogSelectedCaseID(null)
    })
  }, [timeLogCustomerLookupValue])

  useEffect(() => {
    let active = true
    if (timeLogCustomerInputLookupValue === '') {
      setTimeLogCustomerOptions(
        timeLogCustomerLookupValue?.name ? [timeLogCustomerLookupValue] : []
      )
      return undefined
    }

    timeLogCreateFetchCustomers(
      { input: timeLogCustomerInputLookupValue },
      (results) => {
        if (active) {
          let newOptions = []

          if (timeLogCustomerLookupValue) {
            newOptions = [timeLogCustomerLookupValue]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setTimeLogCustomerOptions(newOptions)
        }

        return () => {
          active = false
        }
      }
    )
  }, [
    timeLogCustomerInputLookupValue,
    timeLogCustomerLookupValue,
    timeLogCreateFetchCustomers,
  ])

  const handleCreateTimeLog = async (values, { setErrors }) => {
    setLoading(true)
    try {
      if (!values.userId) {
        throw new Error('A User is Required')
      }
      if (!timeLogSelectedCaseID) {
        throw new Error(
          'You must select a case for a user. Please search for a customer to see all relevant cases'
        )
      }
      if (!values.logDate || new Date(values.logDate) === 'Invalid Date') {
        throw new Error('You must enter a valid date for this log.')
      }

      let parsedHours = null
      if (!isNaN(values.hours)) {
        parsedHours = parseInt(values.hours, 10)
        if (parsedHours < 0 || parsedHours.toString() !== values.hours) {
          parsedHours = null
        }
      }
      if (parsedHours === null) {
        throw new Error('Hours Spent must be a positive integer value')
      }
      if (isNaN(values.minutes)) {
        throw new Error('You must select the minutes you spent on this case')
      }

      if (values.minutes === 0 && parsedHours === 0) {
        throw new Error(
          'AN amount of time must have been spent to create this time log'
        )
      }
      await timeLogCreate(
        values.userId,
        timeLogSelectedCaseID,
        values.logDate,
        values.description,
        parsedHours,
        values.minutes
      )
      resetTimeLogForm()
      setOpenTimeLogCreate(false)
      setTimeLogFilterParams({
        ...timeLogFilterParams,
        offest: 0,
        limit: 10,
      })
      loadMoreTimelogs()
    } catch (err) {
      console.log(err)
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
    return true
  }

  /// TIMELOG FILTER

  const timeLogFilterLoadInNewLogs = (resp) => {
    let getNextParams = {
      ...timeLogFilterParams,
    }
    let newTimeLogs = []
    let hasNewTimelogs = getNextParams.offset > 0
    if (resp?.time_logs) {
      if (resp.limit > 0 && resp.time_logs.length === resp.limit) {
        setTimeLogsHaveMore(true)
        getNextParams.offset += resp.limit
      } else {
        getNextParams.offset += resp.time_logs.length
        setTimeLogsHaveMore(false)
      }
      setTimeLogClientCaseParams(getNextParams)
      newTimeLogs = resp.time_logs
      if (hasNewTimelogs) {
        setTimeLogs([...timeLogs, ...newTimeLogs])
      } else {
        setTimeLogs([...newTimeLogs])
      }
    } else {
      setTimeLogsHaveMore(false)
    }
  }

  const loadMoreTimelogs = () => {
    timeLogSearch({
      ...timeLogFilterParams,
    }).then((resp) => {
      timeLogFilterLoadInNewLogs(resp)
    })
  }
  const handleTimeLogFilter = async (values, { setErrors }) => {
    setLoading(true)
    try {
      let payload = {
        ...timeLogFilterParamsDefault,
      }
      if (values.userId) {
        payload.user_id = values.userId
      }
      if (values.clientCaseId) {
        payload.client_case_id = parseInt(values.clientCaseId)
      }
      if (values.dateAfter) {
        payload.time_log_date_after = new Date(values.dateAfter)
        payload.time_log_date_after.setMilliseconds(0)
        payload.time_log_date_after.setHours(0)
        payload.time_log_date_after.setMinutes(0)
        payload.time_log_date_after.setSeconds(0)
        payload.time_log_date_after = payload.time_log_date_after.toISOString()
      }
      if (values.dateBefore) {
        payload.time_log_date_before = new Date(values.dateBefore)
        payload.time_log_date_before.setMilliseconds(999)
        payload.time_log_date_before.setHours(23)
        payload.time_log_date_before.setMinutes(59)
        payload.time_log_date_before.setSeconds(59)
        payload.time_log_date_before =
          payload.time_log_date_before.toISOString()
      }
      setTimeLogFilterParams(payload)
      timeLogFilterLoadInNewLogs(await timeLogSearch(payload))
    } catch (err) {
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
    return true
  }

  useEffect(() => {
    setUserAdminOrStaff(user && (user.type === 'Staff' || user.isAdmin))
  }, [user])

  useEffect(() => {
    if (userAdminOrStaff) {
      ;(async function fetchUserDataForStaff() {
        try {
          let dbUsers = await userSearch({})
          let userOpts = []
          let newUserNameMap = {}
          if (Array.isArray(dbUsers) && dbUsers.length > 0) {
            for (let i in dbUsers) {
              let userParts = []
              if (dbUsers[i].user_first_name) {
                userParts.push(dbUsers[i].user_first_name)
              }
              if (dbUsers[i].user_last_name) {
                userParts.push(dbUsers[i].user_last_name)
              }
              if (userParts.length === 0) {
                userParts.push(dbUsers[i].user_email)
              }

              let display = userParts.join(' ')
              userOpts.push({
                value: dbUsers[i].user_id,
                display: display,
              })

              newUserNameMap[dbUsers[i].user_id] = display
            }
            setUserNameMap(newUserNameMap)
            setUsers(userOpts)
          }
        } catch (err) {}
      })()
    }
  }, [userAdminOrStaff])

  useEffect(() => {
    let newTimeLogDisplay = timeLogs.map((timeLog) => {
      if (userAdminOrStaff) {
        return {
          User:
            userNameMap && userNameMap[timeLog.user_id]
              ? userNameMap[timeLog.user_id]
              : timeLog.user_id,
          'Case ID': timeLog.client_case_id,
          Date: format(parseISO(timeLog.time_log_date), 'M/d/y'),
          Time: `${timeLog.time_log_hours || '0'}h${
            timeLog.time_log_minutes || 0
          }m`,
          Description: timeLog.time_log_description,
        }
      } else {
        return {
          'Case ID': timeLog.client_case_id,
          Date: format(parseISO(timeLog.time_log_date), 'M/d/y'),
          Time: `${timeLog.time_log_hours || '0'}h${
            timeLog.time_log_minutes || 0
          }m`,
          Description: timeLog.time_log_description,
        }
      }
    })
    setTimeLogDisplay(newTimeLogDisplay)
  }, [userNameMap, timeLogs])

  // EXPENSE LOG CODE

  const formExpenseLogRef = useRef(null)
  const formExpenseLogFilterRef = useRef(null)
  const [
    expenseLogCustomerInputLookupValue,
    setExpenseLogCustomerInputLookupValue,
  ] = useState('')
  const [expenseLogCustomerLookupValue, setExpenseLogCustomerLookupValue] =
    useState('')
  const [expenseLogCustomerOptions, setExpenseLogCustomerOptions] = useState([])
  const [expenseLogCustomerCaseOptions, setExpenseLogCustomerCaseOptions] =
    useState([])

  const [expenseLogClientCaseParams, setExpenseLogClientCaseParams] = useState(
    expenseLogClientCaseParamsDefault
  )

  const [expenseLogCustomerCasesHaveMore, setExpenseLogCustomerCasesHaveMore] =
    useState(false)
  const [expenseLogSelectedCaseID, setExpenseLogSelectedCaseID] = useState(null)
  const [openExpenseLogCreate, setOpenExpenseLogCreate] = useState(false)

  const [expenseLogFilterOpen, setExpenseLogFilterOpen] = useState(false)
  const [expenseLogFilterParams, setExpenseLogFilterParams] = useState(
    expenseLogFilterParamsDefault
  )
  const [expenseLogs, setExpenseLogs] = useState([])
  const [expenseLogDisplay, setExpenseLogDisplay] = useState([])
  const [expenseLogsHaveMore, setExpenseLogsHaveMore] = useState(false)

  const resetExpenseLogForm = () => {
    setExpenseLogSelectedCaseID(null)
    setExpenseLogCustomerLookupValue('')
    setExpenseLogCustomerInputLookupValue('')
    setExpenseLogClientCaseParams(expenseLogClientCaseParamsDefault)
    formExpenseLogRef.current.resetForm()
  }

  const expenseLogCreateFetchCustomers = useMemo(
    () =>
      throttle((request, callback) => {
        let customerNameSearch =
          typeof request === 'string' ? request : request.input
        customerSearch({
          customerName: customerNameSearch,
          limit: 10,
        }).then((results) => {
          callback(
            results
              ? results.map((customer) => {
                  return {
                    id: customer.customer_id,
                    display: `${customer.customer_first_name} ${customer.customer_last_name} <${customer.customer_email}>`,
                  }
                })
              : []
          )
        })
      }, 200),
    []
  )

  const expenseLogCreateLoadInNewCustomerClientCases = (resp) => {
    let getNextParams = {
      ...expenseLogClientCaseParams,
    }
    let clientCases = []
    if (resp?.client_cases) {
      if (resp.limit > 0 && resp.client_cases.length === resp.limit) {
        setExpenseLogCustomerCasesHaveMore(true)
        getNextParams.offset += resp.limit
      } else {
        getNextParams.offset += resp.client_cases.length
        setExpenseLogCustomerCasesHaveMore(false)
      }
      setExpenseLogClientCaseParams(getNextParams)
      clientCases = resp.client_cases.map((clientCase) => {
        return {
          ID: (
            <FormControlLabel
              value={clientCase.client_case_id}
              control={<Radio />}
              label={clientCase.client_case_id}
            />
          ),
          'Due Date': format(
            parseISO(clientCase.client_case_due_date),
            'M/d/y'
          ),
          Status: clientCase.client_case_status,
          Created: format(
            parseISO(clientCase.client_case_created_datetime),
            'M/d/Y h:mm a'
          ),
        }
      })
      setExpenseLogCustomerCaseOptions([
        ...expenseLogCustomerCaseOptions,
        ...clientCases,
      ])
    } else {
      setExpenseLogCustomerCasesHaveMore(false)
    }
  }

  const expenseLogCreateLoadMoreClientCases = () => {
    clientCaseSearch({
      ...expenseLogClientCaseParams,
      customer_id: expenseLogCustomerLookupValue.id,
    }).then((resp) => {
      expenseLogCreateLoadInNewCustomerClientCases(resp)
    })
  }
  useEffect(() => {
    if (!expenseLogCustomerLookupValue?.id) {
      setExpenseLogCustomerCaseOptions([])
      return
    }
    clientCaseSearch({
      ...expenseLogClientCaseParams,
      customer_id: expenseLogCustomerLookupValue.id,
    }).then((resp) => {
      expenseLogCreateLoadInNewCustomerClientCases(resp)
      setExpenseLogSelectedCaseID(null)
    })
  }, [expenseLogCustomerLookupValue])

  useEffect(() => {
    let active = true
    if (expenseLogCustomerInputLookupValue === '') {
      setExpenseLogCustomerOptions(
        expenseLogCustomerLookupValue?.name
          ? [expenseLogCustomerLookupValue]
          : []
      )
      return undefined
    }

    expenseLogCreateFetchCustomers(
      { input: expenseLogCustomerInputLookupValue },
      (results) => {
        if (active) {
          let newOptions = []

          if (expenseLogCustomerLookupValue) {
            newOptions = [expenseLogCustomerLookupValue]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setExpenseLogCustomerOptions(newOptions)
        }

        return () => {
          active = false
        }
      }
    )
  }, [
    expenseLogCustomerInputLookupValue,
    expenseLogCustomerLookupValue,
    expenseLogCreateFetchCustomers,
  ])

  const handleCreateExpenseLog = async (values, { setErrors }) => {
    setLoading(true)
    try {
      await expenseLogCreate(
        values.userId,
        expenseLogSelectedCaseID,
        values.logDate,
        values.description,
        Math.floor(parseFloat(values.price) * 100),
        values.status
      )
      resetExpenseLogForm()
      setOpenExpenseLogCreate(false)
      setExpenseLogFilterParams({
        ...expenseLogFilterParams,
        offest: 0,
        limit: 10,
      })
      loadMoreExpenseLogs()
    } catch (err) {
      console.log(err)
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
    return true
  }

  /// EXPENSELOG FILTER

  const expenseLogFilterLoadInNewLogs = (resp) => {
    let getNextParams = {
      ...expenseLogFilterParams,
    }
    let newExpenseLogs = []
    let hasNewExpenseLogs = getNextParams.offset > 0
    if (resp?.expense_logs) {
      if (resp.limit > 0 && resp.expense_logs.length === resp.limit) {
        setExpenseLogsHaveMore(true)
        getNextParams.offset += resp.limit
      } else {
        getNextParams.offset += resp.expense_logs.length
        setExpenseLogsHaveMore(false)
      }
      setExpenseLogClientCaseParams(getNextParams)
      newExpenseLogs = resp.expense_logs
      if (hasNewExpenseLogs) {
        setExpenseLogs([...expenseLogs, ...newExpenseLogs])
      } else {
        setExpenseLogs([...newExpenseLogs])
      }
    } else {
      setExpenseLogsHaveMore(false)
    }
  }

  const loadMoreExpenseLogs = () => {
    expenseLogSearch({
      ...expenseLogFilterParams,
    }).then((resp) => {
      expenseLogFilterLoadInNewLogs(resp)
    })
  }

  const handleExpenseLogFilter = async (values, { setErrors }) => {
    setLoading(true)
    try {
      let payload = {
        ...expenseLogFilterParamsDefault,
      }
      if (values.userId) {
        payload.user_id = values.userId
      }
      if (values.clientCaseId) {
        payload.client_case_id = parseInt(values.clientCaseId)
      }
      if (values.dateAfter) {
        payload.expense_log_date_after = new Date(values.dateAfter)
        payload.expense_log_date_after.setMilliseconds(0)
        payload.expense_log_date_after.setHours(0)
        payload.expense_log_date_after.setMinutes(0)
        payload.expense_log_date_after.setSeconds(0)
        payload.expense_log_date_after =
          payload.expense_log_date_after.toISOString()
      }
      if (values.dateBefore) {
        payload.expense_log_date_before = new Date(values.dateBefore)
        payload.expense_log_date_before.setMilliseconds(999)
        payload.expense_log_date_before.setHours(23)
        payload.expense_log_date_before.setMinutes(59)
        payload.expense_log_date_before.setSeconds(59)
        payload.expense_log_date_before =
          payload.expense_log_date_before.toISOString()
      }
      setExpenseLogFilterParams(payload)
      expenseLogFilterLoadInNewLogs(await expenseLogSearch(payload))
    } catch (err) {
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
    return true
  }

  useEffect(() => {
    let newExpenseLogDisplay = expenseLogs.map((expenseLog) => {
      if (userAdminOrStaff) {
        return {
          User:
            userNameMap && userNameMap[expenseLog.user_id]
              ? userNameMap[expenseLog.user_id]
              : expenseLog.user_id,
          'Case ID': expenseLog.client_case_id,
          Date: format(parseISO(expenseLog.expense_log_date), 'M/d/y'),
          Cost: '$' + (expenseLog.expense_log_cents / 100).toFixed(2),
          Description: expenseLog.expense_log_description,
        }
      } else {
        return {
          'Case ID': expenseLog.client_case_id,
          Date: format(parseISO(expenseLog.expense_log_date), 'M/d/y'),
          Cost: '$' + (expenseLog.expense_log_cents / 100).toFixed(2),
          Description: expenseLog.expense_log_description,
        }
      }
    })
    setExpenseLogDisplay(newExpenseLogDisplay)
  }, [userNameMap, expenseLogs])

  const handleTabChange = (event, newTabValue) => {
    if (newTabValue === 'expenselogs') {
      setExpenseLogFilterOpen(false)
      setOpenExpenseLogCreate(false)
    } else if (newTabValue === 'timelogs') {
      setTimeLogFilterOpen(false)
      setOpenTimeLogCreate(false)
    }
    setTabState(newTabValue)
  }

  useEffect(() => {
    if (tabState === 'timelogs' && formTimeLogFilterRef?.current) {
      formTimeLogFilterRef.current.resetForm()
      formTimeLogFilterRef.current.submitForm()
    } else if (tabState === 'expenselogs' && formExpenseLogFilterRef?.current) {
      formExpenseLogFilterRef.current.resetForm()
      formExpenseLogFilterRef.current.submitForm()
    }
  }, [tabState, formTimeLogFilterRef, formExpenseLogFilterRef, users])

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabContext value={tabState}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="case tabs">
            <Tab label="Time Logs" value="timelogs" />
            <Tab label="Expense Logs" value="expenselogs" />
          </TabList>
        </Box>

        <TabPanel value="timelogs" index={0}>
          <Typography component="h2" variant="h2" mb={1}>
            <strong>Time Logs</strong>
          </Typography>
          <Accordion
            expanded={openTimeLogCreate}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                if (openTimeLogCreate) {
                  resetTimeLogForm()
                }
                setOpenTimeLogCreate(!openTimeLogCreate)
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>Create New Time Log</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {formTimeLogRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formTimeLogRef?.current?.errors?.submit}
                </Alert>
              )}
              <Formik
                initialValues={{
                  logDate: null,
                  userId: user?.id,
                  minutes: 0,
                }}
                onSubmit={handleCreateTimeLog}
                innerRef={formTimeLogRef}
              >
                <Stack mt={2} mb={2} p={1}>
                  <Grid container spacing={2} mt={1}>
                    {userAdminOrStaff &&
                    Array.isArray(users) &&
                    users.length > 0 ? (
                      <Grid item xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'userId',
                            display: 'User',
                            type: 'select',
                            fieldType: 'select',
                            options: users,
                            InputProps: {
                              required: true,
                              disabled: loading,
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Autocomplete
                        id="time-log-cusotmer"
                        getOptionLabel={(option) =>
                          typeof option === 'string' ? option : option.display
                        }
                        filterOptions={(x) => x}
                        options={timeLogCustomerOptions}
                        autoComplete
                        popupIcon={false}
                        fullWidth={true}
                        includeInputInList
                        filterSelectedOptions
                        value={timeLogCustomerLookupValue}
                        onChange={(event, newValue) => {
                          setTimeLogClientCaseParams(
                            timeLogClientCaseParamsDefault
                          )
                          setTimeLogCustomerLookupValue(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                          setTimeLogCustomerInputLookupValue(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select a customer"
                            fullWidth={true}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>{option.display}</Grid>
                              </Grid>
                            </li>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {timeLogCustomerLookupValue ? (
                        timeLogCustomerCaseOptions ? (
                          <RadioGroup
                            name="clientCaseID"
                            required={true}
                            disabled={loading}
                            value={timeLogSelectedCaseID}
                            onChange={(e) => {
                              setTimeLogSelectedCaseID(
                                parseInt(e.target.value, 10)
                              )
                            }}
                          >
                            <DataTable
                              title="Client Case *"
                              tableHeadStyling={{
                                whiteSpace: 'nowrap',
                              }}
                              tableCellStyling={{
                                whiteSpace: 'nowrap',
                              }}
                              tableRows={timeLogCustomerCaseOptions || []}
                              seeMoreState={timeLogCustomerCasesHaveMore}
                              seeMoreFunction={timeLogCreateLoadMoreClientCases}
                            />
                          </RadioGroup>
                        ) : (
                          <Typography component="i">
                            This Customer has no cases
                          </Typography>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'logDate',
                          display: 'Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            required: true,
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        fieldData={{
                          field: 'hours',
                          display: 'Hours Spent (hh)',
                          type: 'text',
                          InputLabelProps: {
                            sx: {
                              width: '100%',
                              textAlign: 'right',
                              '&.MuiInputLabel-shrink': {
                                textAlign: 'left',
                              },
                            },
                          },
                          InputProps: {
                            required: true,
                            disabled: loading,
                          },
                          inputProps: {
                            style: {
                              maxWidth: '48%',
                              minWidth: '48%',
                            },
                            inputProps: {
                              style: {
                                textAlign: 'right',
                              },
                            },
                          },
                        }}
                      />
                      <TextField
                        sx={{
                          width: '7%',
                          maxWidth: '7%',
                          marginLeft: '-1%',
                          marginRight: '-1%',
                          zIndex: 2,
                          backgroundColor: '#FFFFFF',
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                            {
                              borderLeft: 0,
                              borderRight: 0,
                              borderRadius: 0,
                            },
                        }}
                        inputProps={{
                          readOnly: true,
                          disabled: true,
                        }}
                        value=":"
                      />
                      <Field
                        fieldData={{
                          field: 'minutes',
                          display: 'Minutes Spent',
                          fieldType: 'select',
                          type: 'select',
                          options: minuteOptions,
                          InputProps: {
                            required: true,
                            disabled: loading,
                          },
                          inputProps: {
                            sx: {
                              width: '47%',
                              maxWidth: '47%',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1} mb={4}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'description',
                          display: 'Description',
                          type: 'text',
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() => formTimeLogRef?.current?.submitForm()}
                      >
                        Add Time Log
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={timeLogFilterOpen}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                setTimeLogFilterOpen(!timeLogFilterOpen)
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>Filter Time Logs</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {formTimeLogFilterRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formTimeLogFilterRef?.current?.errors?.submit}
                </Alert>
              )}
              <Formik
                initialValues={{
                  userId: user?.id,
                  dateAfter: null,
                  dateBefore: null,
                }}
                onSubmit={handleTimeLogFilter}
                innerRef={formTimeLogFilterRef}
              >
                <Stack mt={2} mb={2} p={1}>
                  <Grid container spacing={2} mt={1}>
                    {userAdminOrStaff &&
                    Array.isArray(users) &&
                    users.length > 0 ? (
                      <Grid item sm={6} xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'userId',
                            display: 'User',
                            type: 'select',
                            fieldType: 'select',
                            options: [{ value: 0, display: 'Any' }, ...users],
                            InputProps: {
                              disabled: loading,
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item sm={userAdminOrStaff ? 6 : 12} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'clientCaseId',
                          display: 'Client Case ID',
                          type: 'text',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'dateAfter',
                          display: 'From Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'dateBefore',
                          display: 'To Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          formTimeLogFilterRef?.current?.submitForm()
                        }
                      >
                        Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Stack mb={5} p={1}>
            {Array.isArray(timeLogDisplay) && timeLogDisplay.length > 0 ? (
              <DataTable
                title="Time Logs"
                tableHeadStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableCellStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableRows={timeLogDisplay || []}
                seeMoreState={timeLogsHaveMore}
                seeMoreFunction={loadMoreTimelogs}
              />
            ) : (
              <Typography component="i" variant="h3" p={1}>
                No time logs match this filter
              </Typography>
            )}
          </Stack>
        </TabPanel>
        <TabPanel value="expenselogs" index={1}>
          <Typography component="h2" variant="h2" mb={1}>
            <strong>Expense Logs</strong>
          </Typography>
          <Accordion
            expanded={openExpenseLogCreate}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                if (openExpenseLogCreate) {
                  resetExpenseLogForm()
                }
                setOpenExpenseLogCreate(!openExpenseLogCreate)
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>Create New Expense Log</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {formExpenseLogRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formExpenseLogRef?.current?.errors?.submit}
                </Alert>
              )}
              <Formik
                initialValues={{
                  logDate: null,
                  userId: user?.id,
                }}
                onSubmit={handleCreateExpenseLog}
                innerRef={formExpenseLogRef}
              >
                <Stack mt={2} mb={2} p={1}>
                  <Grid container spacing={2} mt={1}>
                    {userAdminOrStaff &&
                    Array.isArray(users) &&
                    users.length > 0 ? (
                      <Grid item xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'userId',
                            display: 'User',
                            type: 'select',
                            fieldType: 'select',
                            options: users,
                            InputProps: {
                              required: true,
                              disabled: loading,
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Autocomplete
                        id="expense-log-cusotmer"
                        getOptionLabel={(option) =>
                          typeof option === 'string' ? option : option.display
                        }
                        options={expenseLogCustomerOptions}
                        autoComplete
                        popupIcon={false}
                        fullWidth={true}
                        includeInputInList
                        filterSelectedOptions
                        value={expenseLogCustomerLookupValue}
                        onChange={(event, newValue) => {
                          setExpenseLogClientCaseParams(
                            expenseLogClientCaseParamsDefault
                          )
                          setExpenseLogCustomerLookupValue(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                          setExpenseLogCustomerInputLookupValue(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select a customer"
                            fullWidth={true}
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item>{option.display}</Grid>
                              </Grid>
                            </li>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {expenseLogCustomerLookupValue ? (
                        expenseLogCustomerCaseOptions ? (
                          <RadioGroup
                            name="clientCaseID"
                            required={true}
                            disabled={loading}
                            value={expenseLogSelectedCaseID}
                            onChange={(e) => {
                              setExpenseLogSelectedCaseID(
                                parseInt(e.target.value, 10)
                              )
                            }}
                          >
                            <DataTable
                              title="Client Case *"
                              tableHeadStyling={{
                                whiteSpace: 'nowrap',
                              }}
                              tableCellStyling={{
                                whiteSpace: 'nowrap',
                              }}
                              tableRows={expenseLogCustomerCaseOptions || []}
                              seeMoreState={expenseLogCustomerCasesHaveMore}
                              seeMoreFunction={
                                expenseLogCreateLoadMoreClientCases
                              }
                            />
                          </RadioGroup>
                        ) : (
                          <Typography component="i">
                            This Customer has no cases
                          </Typography>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'logDate',
                          display: 'Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            required: true,
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'price',
                          display: 'Total Cost (XX.XX)',
                          type: 'text',
                          InputProps: {
                            required: true,
                            disabled: loading,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          },
                          inputProps: {
                            inputProps: {
                              sx: {
                                textAlign: 'right',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1} mb={4}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'description',
                          display: 'Description',
                          type: 'text',
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() => formExpenseLogRef?.current?.submitForm()}
                      >
                        Add Expense Log
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expenseLogFilterOpen}
            sx={{ border: '1px solid black' }}
          >
            <AccordionSummary
              onClick={() => {
                setExpenseLogFilterOpen(!expenseLogFilterOpen)
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>Filter Expense Logs</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {formExpenseLogFilterRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formExpenseLogFilterRef?.current?.errors?.submit}
                </Alert>
              )}
              <Formik
                initialValues={{
                  userId: user?.id,
                  dateAfter: null,
                  dateBefore: null,
                }}
                onSubmit={handleExpenseLogFilter}
                innerRef={formExpenseLogFilterRef}
              >
                <Stack mt={2} mb={2} p={1}>
                  <Grid container spacing={2} mt={1}>
                    {userAdminOrStaff &&
                    Array.isArray(users) &&
                    users.length > 0 ? (
                      <Grid item sm={6} xs={12}>
                        <FieldGroup
                          fieldData={{
                            field: 'userId',
                            display: 'User',
                            type: 'select',
                            fieldType: 'select',
                            options: [{ value: 0, display: 'Any' }, ...users],
                            InputProps: {
                              disabled: loading,
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item sm={userAdminOrStaff ? 6 : 12} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'clientCaseId',
                          display: 'Client Case ID',
                          type: 'text',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'dateAfter',
                          display: 'From Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'dateBefore',
                          display: 'To Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        disabled={loading}
                        fullWidth={true}
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          formExpenseLogFilterRef?.current?.submitForm()
                        }
                      >
                        Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Stack mb={2} p={1}>
            {Array.isArray(expenseLogDisplay) &&
            expenseLogDisplay.length > 0 ? (
              <DataTable
                title=""
                tableHeadStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableCellStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableRows={expenseLogDisplay || []}
                seeMoreState={expenseLogsHaveMore}
                seeMoreFunction={loadMoreExpenseLogs}
              />
            ) : (
              <Typography component="i" variant="h3" p={1}>
                No expense logs match this filter
              </Typography>
            )}
          </Stack>
        </TabPanel>
      </TabContext>
    </Paper>
  )
}

export default TimeExpenseLog
