import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { format, parseISO } from 'date-fns'
import useUsers from 'hooks/useUser'
import useAuth from 'hooks/context/useAuth'
import UserCreateEditForm from 'components/users/UserCreateEditForm'

function Users() {
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const [userDisplay, setUserDisplay] = useState([])
  const [users, setUsers] = useState([])
  const [userSort, setUserSort] = useState('user_id')
  const [userSortAsc, setUserSortAsc] = useState(true)
  const { userSearch } = useUsers()

  useEffect(() => {
    let newDisplay = []
    if (Array.isArray(users) && users.length > 0) {
      newDisplay = [...users]
      let sortDirection = userSortAsc ? 1 : -1
      let isUserFullName = userSort === 'name'
      newDisplay.sort((a, b) => {
        let aVal = isUserFullName
          ? `${a.user_first_name} ${a.user_last_name}`
          : a[userSort]
        let bVal = isUserFullName
          ? `${b.user_first_name} ${b.user_last_name}`
          : b[userSort]
        if (aVal === undefined && bVal === undefined) {
          return sortDirection * (a.user_id - b.user_id)
        } else if (aVal === undefined) {
          return -1 * sortDirection
        } else if (bVal === undefined) {
          return sortDirection
        }
        if (!isNaN(aVal)) {
          return sortDirection * (aVal - bVal)
        } else if (typeof aVal === 'string') {
          if (userSort === 'user_name')
            return (
              sortDirection *
              a.localeCompare(
                bVal,
                navigator.languages[0] || navigator.language,
                { numeric: true, ignorePunctuation: true }
              )
            )
        } else if (typeof aVal === 'boolean') {
          return sortDirection * (aVal === bVal ? 0 : aVal ? 1 : -1)
        }
        // all other sorting has failed, so fall back to user id, because it will be there
        return sortDirection * (a.user_id - b.user_id)
      })
    }
    setUserDisplay(newDisplay)
  }, [users, userSort, userSortAsc])

  const loadUsers = async () => {
    if (!loading) {
      setLoading(true)
      try {
        let newUsers = await userSearch()
        setUsers(newUsers)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    ;(async function fetchInitialData() {
      await loadUsers()
    })()
  }, [])

  const setSortCall = (newSortParam) => {
    if (userSort === newSortParam) {
      setUserSortAsc(!userSortAsc)
    } else {
      setUserSortAsc(true)
      setUserSort(newSortParam)
    }
  }

  const [createUserOpen, setCreateUserOpen] = useState(false)
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1" mb={2} mt={4}>
            Users
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Button
            variant="contained"
            sx={{ marginBottom: '5px' }}
            onClick={() => {
              setCreateUserOpen((createUserOpen) => !createUserOpen)
            }}
          >
            {createUserOpen ? '[-]' : '[+]'}Create User
          </Button>
          <Collapse in={createUserOpen}>
            <UserCreateEditForm
              submitSuccessCallback={() => {
                setCreateUserOpen(false)
                loadUsers()
              }}
            />
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div
                      role="link"
                      tabIndex="0"
                      onClick={() => {
                        setSortCall('name')
                      }}
                    >
                      Name
                      {userSort === 'name' ? (
                        <>&nbsp;{userSortAsc ? <>&uarr;</> : <>&darr;</>}</>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      role="link"
                      tabIndex="0"
                      onClick={() => {
                        setSortCall('user_is_admin')
                      }}
                    >
                      Is Admin?
                      {userSort === 'user_is_admin' ? (
                        <>&nbsp;{userSortAsc ? <>&uarr;</> : <>&darr;</>}</>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      role="link"
                      tabIndex="0"
                      onClick={() => {
                        setSortCall('user_type')
                      }}
                    >
                      User Type
                      {userSort === 'user_type' ? (
                        <>&nbsp;{userSortAsc ? <>&uarr;</> : <>&darr;</>}</>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    <div
                      role="link"
                      tabIndex="0"
                      onClick={() => {
                        setSortCall('user_modified_datetime')
                      }}
                    >
                      Last Modified
                      {userSort === 'user_modified_datetime' ? (
                        <>&nbsp;{userSortAsc ? <>&uarr;</> : <>&darr;</>}</>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    <div
                      role="link"
                      tabIndex="0"
                      onClick={() => {
                        setSortCall('user_created_datetime')
                      }}
                    >
                      Created
                      {userSort === 'user_created_datetime' ? (
                        <>&nbsp;{userSortAsc ? <>&uarr;</> : <>&darr;</>}</>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userDisplay.map((detailedUser) => {
                  return (
                    <TableRow hover key={'user_' + detailedUser.user_id}>
                      <TableCell>
                        {`${detailedUser.user_first_name} ${detailedUser.user_last_name}`}
                      </TableCell>
                      <TableCell>
                        {detailedUser.user_is_admin ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>{detailedUser.user_type}</TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        {detailedUser.user_modified_datetime
                          ? format(
                              parseISO(detailedUser.user_modified_datetime),
                              'M/d/Y h:mm a'
                            )
                          : ''}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        {detailedUser.user_created_datetime
                          ? format(
                              parseISO(detailedUser.user_created_datetime),
                              'M/d/Y h:mm a'
                            )
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Link to={`/console/users/${detailedUser.user_id}`}>
                          <Button variant="contained" fullWidth={true}>
                            {!user.isAdmin || detailedUser.user_is_admin
                              ? 'View'
                              : 'Manage'}
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default Users
