import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'

import useCustomerAuth from 'hooks/context/useCustomerAuth'
import resolveErrorText from 'helpers/resolveErrorText'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'

function CustomerLogin() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailFound, setEmailFound] = useState(false)
  const { login, customerSendTOTP } = useCustomerAuth()
  const formikRef = useRef(null)

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)

    try {
      if (emailFound && email && values.totp) {
        await login({ ...values, email })
      } else if (values?.email || (emailFound && email)) {
        let usedEmail = emailFound ? email : values?.email
        if (await customerSendTOTP({ email: values?.email || email })) {
          setEmail(usedEmail)
          setEmailFound(true)
        } else {
          setEmail(null)
          setEmailFound(false)
          throw new Error('Could not send one time password - try again')
        }
      } else {
        throw new Error('Email is required')
      }
      //await login(values)
    } catch (err) {
      setErrors({
        submit: resolveErrorText(err),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Formik
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validateOnChange={false}
          initialValues={{
            email: '',
            totp: '',
          }}
          p={2}
        >
          <>
            <Typography component="h1" variant="h1" mt={2} p={1}>
              Customer Portal Login
            </Typography>
            <Stack mt={2} mb={2}>
              {formikRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formikRef?.current?.errors?.submit}
                </Alert>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <FieldGroup
                    fieldData={{
                      field: 'email',
                      display: 'Your Email',
                      type: 'text',
                      InputProps: {
                        disabled: emailFound || loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldGroup
                    fieldData={{
                      field: 'totp',
                      display: 'One-Time Passcode',
                      type: 'text',
                      hide: () => {
                        return emailFound ? false : true
                      },
                      InputProps: {
                        disabled: !emailFound || loading,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: emailFound ? '' : 'none' }}
                >
                  <Button
                    fullWidth={true}
                    disabled={loading || !emailFound}
                    color="info"
                    variant="contained"
                    onClick={() => {
                      try {
                        setLoading(true)
                        customerSendTOTP({ email })
                      } finally {
                        setLoading(false)
                      }
                    }}
                  >
                    Resend Code
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: emailFound ? '' : 'none' }}
                >
                  <Button
                    fullWidth={true}
                    disabled={loading || !emailFound}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setEmailFound(false)
                      setEmail(null)
                    }}
                  >
                    Clear Email
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={loading}
                    fullWidth={true}
                    color="primary"
                    variant="contained"
                    onClick={() => formikRef?.current?.submitForm()}
                  >
                    {emailFound ? 'Login' : 'Send One-Time Passcode'}
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </>
        </Formik>
      </Box>
    </Container>
  )
}

export default CustomerLogin
