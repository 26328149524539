import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import MFACode from 'components/userlogin/MFACode'
import Slideshow from 'components/common/Slideshow'

import useAuth from 'hooks/context/useAuth'

function MFA() {
  const { payload: qrCode } = useAuth()
  const [frame, setFrame] = useState(0)

  const handleClick = () => {
    setFrame(frame ^ 1)
  }

  return (
    <Box sx={{ width: '20vw' }}>
      <Slideshow frame={frame}>
        <>
          <Typography component="h1" variant="h1" mb={4}>
            QR Code
          </Typography>
          <Typography component="h2" variant="h2" mb={4}>
            Scan with your authenication app of choice
          </Typography>
          <Box>
            <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
          </Box>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Continue
          </Button>
        </>
        <Box>
          <MFACode setFrame={setFrame} />
        </Box>
      </Slideshow>
    </Box>
  )
}

export default MFA
