import React, { useReducer, createContext, useEffect } from 'react'

import axios from 'utils/axios'

import isValidToken from 'helpers/auth/isValidToken'

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: {
    id: null,
    isAdmin: false,
    type: false,
    name: '',
  },
  token: {
    accessToken: '',
    accessTokenExpires: '',
    refreshToken: '',
    refreshTokenExpires: '',
  },
  challenge: '',
  payload: '',
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, isAuthenticated, payload, ...rest } = state ?? {}

  if (isValidToken(state?.token ?? {}))
    localStorage.userState = JSON.stringify(rest)
  else delete localStorage.userState
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isInitialized: true,
        user: action.payload.user,
        token: action.payload.token,
        challenge: action.payload.challenge,
        payload: action.payload.payload,
      }
    case 'LOGIN':
      return {
        ...state,
        isInitialized: true,
        token: action.payload.token,
        challenge: action.payload?.challenge,
        payload: action.payload?.payload,
      }
    case 'LOGOUT': {
      delete localStorage.userState
      delete axios.defaults.headers.Authorization
      return {
        ...initialState,
        isInitialized: true,
      }
    }
    case 'UPDATE_TOKEN': {
      const {
        accessToken,
        accessTokenExpires,
        refreshToken,
        refreshTokenExpires,
      } = action.payload

      let coupledUpdate = {
        ...state.token,
        accessToken,
        accessTokenExpires,
      }

      if (refreshToken) {
        coupledUpdate.refreshToken = refreshToken
      }
      if (refreshTokenExpires) {
        coupledUpdate.refreshTokenExpires = refreshTokenExpires
      }

      // set the authorization header for all future requests
      // delete customer state if exists
      if (localStorage.customerState) {
        delete localStorage.customerState
      }

      axios.defaults.headers.Authorization = `Bearer ${accessToken}`

      return {
        ...state,
        token: {
          ...state.token,
          ...coupledUpdate,
        },
      }
    }
    case 'UPDATE_USER':
      const { user } = action.payload
      return {
        ...state,
        user,
      }
    case 'UPDATE_CHALLENGE': {
      const { challenge, ...props } = action.payload

      return {
        ...state,
        ...props,
        challenge,
      }
    }
    case 'SET_AUTHENTICATION': {
      const { isAuthenticated } = action.payload

      return {
        ...state,
        isAuthenticated,
      }
    }
    case 'STORE_STATE': {
      storeState(state)
      return state
    }
    default:
      return state
  }
}

const AuthContext = createContext(null)

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const updateToken = ({
    token,
    token_expires,
    refresh_token,
    refresh_token_expires,
  }) => {
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: {
        accessToken: token,
        accessTokenExpires: token_expires,
        refreshToken: refresh_token,
        refreshTokenExpires: refresh_token_expires,
      },
    })
  }

  const updateUser = async () => {
    if (state?.token?.accessToken) {
      try {
        const res = await axios.get('/user/get')
        if (res.status === 200) {
          dispatch({
            type: 'UPDATE_USER',
            payload: {
              user: {
                id: res.data.user_id,
                isAdmin: res.data.user_is_admin,
                type: res.data.user_type,
                name: `${res.data.user_first_name} ${res.data.user_last_name}`,
              },
            },
          })
        }
      } catch (err) {
        // fire off logout call.
        await logout()
      }
    }
  }

  const refreshTokens = async () => {
    if (state?.token?.refreshToken) {
      try {
        const res = await axios.post(
          '/auth/refresh',
          {
            refreshToken: state.token.refreshToken,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token.refreshToken}`,
            },
          }
        )

        if (res.status === 200) {
          const refreshData = res.data

          updateToken(refreshData)
          await updateUser()

          return refreshData
        }

        throw new Error('Failed to refresh token')
      } catch (err) {
        return null
      }
    }
    return null
  }

  const login = async ({ email, password }) => {
    // axios amends base API endpoint by default
    // see utils/axios for default settings
    const res = await axios.post(
      `/auth/login`,
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const loginData = res.data
    const accessToken = loginData.token

    // store user data in global state
    dispatch({
      type: 'INITIALIZE',
      payload: {
        token: {
          accessToken,
          accessTokenExpires: loginData.token_expires,
        },
        challenge: loginData.challenge,
        payload: loginData.payload,
      },
    })

    updateToken(loginData)

    return loginData
  }

  const logout = async () => {
    // do not cancel the logout process
    try {
      axios.post(
        `/auth/logout`,
        {
          refreshToken: state.token.refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token.refreshToken}`,
          },
        }
      )
    } catch (err) {}

    dispatch({
      type: 'LOGOUT',
    })
  }

  const resetPassword = async ({
    current_password,
    new_password,
    new_password_confirm,
  }) => {
    const res = await axios.post(`/user/resetPassword`, {
      current_password,
      new_password,
      new_password_confirm,
    })

    if (res.status !== 204) {
      throw new Error('Password reset failed')
    }

    await logout()
  }

  const resetTempPasswordChallenge = async ({
    current_password,
    new_password,
    new_password_confirm,
  }) => {
    const res = await axios.post(`/auth/resetTempPassword`, {
      current_password,
      new_password,
      new_password_confirm,
    })

    if (res.status !== 200) {
      throw new Error(res.data)
    }

    dispatch({
      type: 'UPDATE_CHALLENGE',
      payload: res.data,
    })
    updateToken(res.data)
  }

  const setupMFA = async ({ mfaSecret, code }) => {
    const res = await axios.post(`/auth/mfaSetup`, {
      secret: mfaSecret,
      code,
    })

    if (res.status === 200) {
      updateToken(res.data)

      dispatch({
        type: 'UPDATE_CHALLENGE',
        payload: res.data,
      })
    } else {
      throw new Error(res.data)
    }
  }

  const enterMFACode = async ({ code }) => {
    const res = await axios.post(`/auth/mfa`, {
      code: code?.toString(),
    })

    if (res.status === 200) {
      updateToken(res.data)

      dispatch({
        type: 'UPDATE_CHALLENGE',
        payload: {
          challenge: '',
        },
      })
      await updateUser()
    }
  }

  const initialize = () => {
    try {
      const userState = JSON.parse(
        localStorage.userState ?? JSON.stringify(initialState)
      )

      if (userState.challenge) {
        // sign out user if they refresh when there's a challenge
        throw new Error('Challenge detected')
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...userState,
          },
        })

        updateToken({
          token: userState.token.accessToken,
          token_expires: userState.token.accessTokenExpires,
        })
      }
    } catch (err) {
      storeState(initialState)
    }
  }

  const isUserAuthenticated = (tokenData) => {
    if (!tokenData) return false
    return isValidToken(tokenData) && state.challenge === ''
  }

  const setAuthentication = (isAuthenticated) => {
    dispatch({
      type: 'SET_AUTHENTICATION',
      payload: {
        isAuthenticated,
      },
    })
  }

  useEffect(initialize, [])

  // store state in local storage whenever state changes in memory
  useEffect(() => {
    const asyncCallback = async () => {
      if (state.isInitialized) {
        let tokenData = state.token

        if (!isValidToken(state.token)) {
          tokenData = await refreshTokens()
        }

        const isAuthenticated = isUserAuthenticated(tokenData)

        if (state.isAuthenticated !== isAuthenticated)
          setAuthentication(isAuthenticated)

        dispatch({ type: 'STORE_STATE' })
      }
    }

    asyncCallback()
  }, [state])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        resetPassword,
        resetTempPasswordChallenge,
        setupMFA,
        enterMFACode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
