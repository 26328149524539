import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Modal from '@mui/material/Modal'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
//import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'

import DataTable from 'components/common/DataTable'
import useClientCase from 'hooks/useClientCase'
import useUser from 'hooks/useUser'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import { format, parseISO } from 'date-fns'

import { useParams } from 'react-router-dom'
//import { textAlign } from '@mui/material/node_modules/@mui/system'
import useAuth from 'hooks/context/useAuth'

function CaseDetail() {
  const formikRef = useRef(null)
  const formInfoRef = useRef(null)
  const formTriageRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const {
    clientCaseConstantsGet,
    clientCaseGet,
    clientCaseActionHistory,
    clientCaseNoteCreate,
    clientCaseUpdate,
    clientCaseTriage,
    clientCaseDocumentList,
    clientCaseDocumentsUpload,
    clientCaseDocumentDownload,
  } = useClientCase()
  const { userSearch } = useUser()
  const { user } = useAuth()

  const [users, setUsers] = useState([])
  const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
  const [clientCaseActionLogs, setClientCaseActionLogs] = useState([])
  const [clientCaseDocuments, setClientCaseDocuments] = useState([])
  const [clientCaseTimings, setClientCaseTimings] = useState([])
  const [clientCaseStatuses, setClientCaseStatuses] = useState([])
  const [clientCaseServiceLevels, setClientCaseServiceLevels] = useState([])
  const [clientCaseAppealTypes, setClientCaseAppealTypes] = useState([])
  const [clientCaseDeterminations, setClientCaseDeterminations] = useState([])
  const [clientCase, setClientCase] = useState(null)

  const [triageModalOpen, setTriageModalOpen] = useState(false)
  const handleTriageModalOpen = () => setTriageModalOpen(true)
  const handleTriageModalClose = () => setTriageModalOpen(false)

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      let payload = {
        client_case_id: clientCase.client_case_id,
        client_case_appeal_type: values.client_case_appeal_type,
        client_case_denied_datetime: values.client_case_denied_datetime,
        client_case_determination: values.client_case_determination,
        client_case_due_date:
          (values?.client_case_due_date || '').substr(0, 10) + 'T12:00:00.000Z',
        client_case_service_datetime: values.client_case_service_datetime,
        client_case_service_level: values.client_case_service_level,
        client_case_timing: values.client_case_timing,
        user_id: values.user_id || null,
      }
      await clientCaseUpdate(payload)

      await loadActionHistory()
    } catch (err) {
      setErrors({
        submit:
          err.response?.data?.message ??
          err?.message ??
          'An error has occurred',
      })
      return false
    } finally {
      setLoading(false)
    }
    return true
  }

  const handleTriageSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      if (!values.client_case_determination) {
        throw new Error(
          'A determination must be made to advance this case out of triage'
        )
      }

      let payload = {
        client_case_id: clientCase.client_case_id,
        customer_insurance_company: values.customer_insurance_company,
        customer_subscriber_relation: values.customer_subscriber_relation,
        client_case_timing: values.client_case_timing,
        client_case_due_date: values.client_case_due_date,
        client_case_appeal_type: values.client_case_appeal_type,
        client_case_service_level: values.client_case_service_level,
        client_case_determination: values.client_case_determination,
        client_case_triage_note_content:
          values.client_case_triage_note_content || null,
      }

      let updatedClientCase = await clientCaseTriage(payload)

      await setClientCase(updatedClientCase)
      await loadActionHistory()
      handleTriageModalClose()
    } catch (err) {
      setErrors({
        submit:
          err.response?.data?.message ??
          err?.message ??
          'An error has occurred',
      })
      return false
    } finally {
      setLoading(false)
    }
    return true
  }

  const handleInfoSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      if (!values.client_case_note_content && !values.files) {
        throw new Error('Must contain note content OR files')
      }

      if (values.client_case_note_content) {
        await clientCaseNoteCreate(
          clientCase.client_case_id,
          values.client_case_note_visibility,
          values.client_case_note_content
        )
      }
      if (values.files) {
        await clientCaseDocumentsUpload(
          clientCase.client_case_id,
          values.files,
          values.client_case_note_visibility
        )
        await loadDocuments()
      }
      await loadActionHistory()
      formInfoRef.current.resetForm()
      setOpenAttachDocuments(false)
    } catch (err) {
      setErrors({
        submit:
          err.response?.data?.message ??
          err?.message ??
          'An error has occurred',
      })
    } finally {
      setLoading(false)
    }
  }

  const loadActionHistory = async () => {
    let dbActionLogs = await clientCaseActionHistory(id)
    let actionLogDisplay = []
    if (Array.isArray(dbActionLogs) && dbActionLogs.length > 0) {
      for (let i in dbActionLogs) {
        actionLogDisplay.push({
          ActionName: dbActionLogs[i].client_case_action_name,
          ActorID: dbActionLogs[i].client_case_action_actor_id,
          ActorType: dbActionLogs[i].client_case_action_actor_type,
          Actor: dbActionLogs[i].client_case_action_actor,
          Description: dbActionLogs[i].client_case_action_description,
          Datetime: format(
            parseISO(dbActionLogs[i].client_case_action_created_datetime),
            'M/d/Y h:mm a'
          ),
          IsInternal: dbActionLogs[i].client_case_action_is_internal,
        })
      }
    }
    setClientCaseActionLogs(actionLogDisplay)
  }

  const loadDocuments = async () => {
    let dbDocs = await clientCaseDocumentList({
      clientCaseID: id,
      limit: 125,
    })
    let displayDocuments = []
    if (dbDocs?.client_case_documents?.length > 0) {
      for (let i in dbDocs.client_case_documents) {
        displayDocuments.push({
          'Uploaded By': dbDocs.client_case_documents[i].user
            ? `${dbDocs.client_case_documents[i].user.user_first_name} ${dbDocs.client_case_documents[i].user.user_last_name}`
            : dbDocs.client_case_documents[i].customer
            ? `${dbDocs.client_case_documents[i].customer.customer_first_name} ${dbDocs.client_case_documents[i].customer.customer_last_name}`
            : 'System',
          // 'Name': dbDocs.client_case_documents[i].client_case_document_name,
          'File Name':
            dbDocs.client_case_documents[i].client_case_document_file_name,
          'File Type':
            dbDocs.client_case_documents[i].client_case_document_file_type,
          'File Size (bytes)':
            dbDocs.client_case_documents[i].client_case_document_file_size,
          'Created Date + Time': format(
            parseISO(
              dbDocs.client_case_documents[i]
                .client_case_document_created_datetime
            ),
            'M/d/Y hh:mm a'
          ),
          Download: dbDocs.client_case_documents[i]
            .client_case_document_cloud_available ? (
            <Button
              variant="contained"
              onClick={() => {
                clientCaseDocumentDownload(
                  dbDocs.client_case_documents[i].client_case_document_id
                )
              }}
            >
              Download
            </Button>
          ) : (
            'Archived'
          ),
        })
      }
    }
    setClientCaseDocuments(displayDocuments)
  }

  useEffect(() => {
    ;(async function fetchInitialData() {
      setLoading(true)
      try {
        let clientCaseConstants = await clientCaseConstantsGet()
        let clientCaseTimingsDisplay = []
        let clientCaseStatusesDisplay = []
        let clientCaseServiceLevelsDisplay = []
        let clientCaseAppealTypesDisplay = []
        let clientCaseDeterminationsDisplay = []
        if (
          Array.isArray(clientCaseConstants?.client_case_timings) &&
          clientCaseConstants.client_case_timings.length > 0
        ) {
          for (let i in clientCaseConstants?.client_case_timings) {
            clientCaseTimingsDisplay.push({
              value: clientCaseConstants.client_case_timings[i],
              display: clientCaseConstants.client_case_timings[i],
            })
          }
        }

        if (
          Array.isArray(clientCaseConstants?.client_case_statuses) &&
          clientCaseConstants.client_case_statuses.length > 0
        ) {
          for (let i in clientCaseConstants?.client_case_statuses) {
            // do not let revert to triage.
            if (clientCaseConstants.client_case_statuses[i] != 'Triage') {
              clientCaseStatusesDisplay.push({
                value: clientCaseConstants.client_case_statuses[i],
                display: clientCaseConstants.client_case_statuses[i],
              })
            }
          }
        }

        if (
          Array.isArray(clientCaseConstants?.client_case_service_levels) &&
          clientCaseConstants.client_case_service_levels.length > 0
        ) {
          for (let i in clientCaseConstants?.client_case_service_levels) {
            clientCaseServiceLevelsDisplay.push({
              value: clientCaseConstants.client_case_service_levels[i],
              display: clientCaseConstants.client_case_service_levels[i],
            })
          }
        }

        if (
          Array.isArray(clientCaseConstants?.client_case_appeal_types) &&
          clientCaseConstants.client_case_appeal_types.length > 0
        ) {
          for (let i in clientCaseConstants?.client_case_appeal_types) {
            clientCaseAppealTypesDisplay.push({
              value: clientCaseConstants.client_case_appeal_types[i],
              display: clientCaseConstants.client_case_appeal_types[i],
            })
          }
        }

        if (
          Array.isArray(clientCaseConstants?.client_case_determinations) &&
          clientCaseConstants.client_case_determinations.length > 0
        ) {
          clientCaseDeterminationsDisplay = [
            { value: 0, display: 'Choose an option' },
          ]
          for (let i in clientCaseConstants?.client_case_determinations) {
            clientCaseDeterminationsDisplay.push({
              value: clientCaseConstants.client_case_determinations[i],
              display: clientCaseConstants.client_case_determinations[i],
            })
          }
        }
        setClientCaseTimings(clientCaseTimingsDisplay)
        setClientCaseStatuses(clientCaseStatusesDisplay)
        setClientCaseServiceLevels(clientCaseServiceLevelsDisplay)
        setClientCaseAppealTypes(clientCaseAppealTypesDisplay)
        setClientCaseDeterminations(clientCaseDeterminationsDisplay)

        let loadedClientCase = await clientCaseGet(id, true)
        let dbUsers = await userSearch({})
        let userOpts = []
        if (Array.isArray(dbUsers) && dbUsers.length > 0) {
          userOpts.push({ value: 0, display: 'Unassigned' })
          for (let i in dbUsers) {
            let userParts = []
            if (dbUsers[i].user_first_name) {
              userParts.push(dbUsers[i].user_first_name)
            }
            if (dbUsers[i].user_last_name) {
              userParts.push(dbUsers[i].user_last_name)
            }
            userParts.push(
              (userParts.length > 0 ? '<' : '') +
                dbUsers[i].user_email +
                (userParts.length > 0 ? '>' : '')
            )

            userOpts.push({
              value: dbUsers[i].user_id,
              display: userParts.join(' '),
            })
          }
        }
        setUsers(userOpts)

        await loadActionHistory()
        await loadDocuments()

        // set this very last
        setClientCase(loadedClientCase)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const error = formikRef?.current?.errors?.submit

  return clientCase ? (
    <>
      <Box>
        {error && (
          <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
        )}
        <Typography component="h1" variant="h1" mb={4}>
          Case Details
        </Typography>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Customer:</TableCell>
                  <TableCell colSpan={2}>
                    {`${clientCase.customer.customer_first_name} ${clientCase.customer.customer_last_name} <${clientCase.customer.customer_email}>`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status:</TableCell>
                  <TableCell
                    colSpan={clientCase.client_case_status === 'Triage' ? 1 : 2}
                  >
                    {clientCase.client_case_status}
                  </TableCell>
                  {clientCase.client_case_status === 'Triage' ? (
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleTriageModalOpen}
                      >
                        Triage Case
                      </Button>
                      <Modal
                        open={triageModalOpen}
                        onClose={handleTriageModalClose}
                        aria-labelledby="triage-modal"
                        aria-describedby="triage-modal-completion"
                      >
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95vw',
                            maxWidth: '800px',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                          }}
                        >
                          <Formik
                            initialValues={{
                              customer_insurance_company:
                                clientCase.customer.customer_insurance_company,
                              customer_subscriber_relation:
                                clientCase.customer
                                  .customer_subscriber_relation,
                              client_case_timing: clientCase.client_case_timing,
                              client_case_due_date:
                                clientCase.client_case_due_date,
                              client_case_appeal_type:
                                clientCase.client_case_appeal_type,
                              client_case_service_level:
                                clientCase.client_case_service_level,
                              client_case_determination: 0,
                            }}
                            // initialStatus={initialStatus}
                            onSubmit={handleTriageSubmit}
                            innerRef={formTriageRef}
                            validateOnChange={false}
                            p={2}
                          >
                            <>
                              <Typography
                                component="h2"
                                variant="h1"
                                mt={2}
                                p={1}
                              >
                                Case Triage
                              </Typography>
                              <Stack mt={2} mb={2} p={1}>
                                {formTriageRef?.current?.errors?.submit && (
                                  <Alert severity="warning">
                                    {formTriageRef?.current?.errors?.submit}
                                  </Alert>
                                )}
                                <Grid container spacing={2} mt={1}>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'customer_insurance_company',
                                        display: 'Insurance Company',
                                        type: 'text',
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'customer_subscriber_relation',
                                        display: 'Subscriber Relation',
                                        type: 'select',
                                        fieldType: 'select',
                                        options: [
                                          'Self',
                                          'Spouse',
                                          'Dependant',
                                          'Other',
                                        ].map((relation) => ({
                                          display: relation,
                                          value: relation,
                                        })),
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'client_case_due_datetime',
                                        display: 'Case Due Date',
                                        type: 'date',
                                        fullWidth: true,
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      component="h2"
                                      variant="h1"
                                      mt={2}
                                      p={1}
                                      style={{ border: '1px solid black' }}
                                    >
                                      TO INSERT: MEDICAL RECORD CHECKLIST
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'client_case_timing',
                                        display: 'Case Denial Timing',
                                        type: 'select',
                                        fieldType: 'select',
                                        options: clientCaseTimings,
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'client_case_service_level',
                                        display: 'Service Level',
                                        type: 'select',
                                        fieldType: 'select',
                                        options: clientCaseServiceLevels,
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FieldGroup
                                      fieldData={{
                                        field: 'client_case_determination',
                                        display: 'Determination',
                                        type: 'select',
                                        fieldType: 'select',
                                        options: clientCaseDeterminations,
                                        InputProps: {
                                          disabled: loading,
                                        },
                                        fullWidth: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <FieldGroup
                                      fieldData={{
                                        field:
                                          'client_case_triage_note_content',
                                        display: 'Leave Optional Note',
                                        type: 'text',
                                        InputProps: {
                                          disabled: loading,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                      <Button
                                        disabled={loading}
                                        color="warning"
                                        fullWidth={true}
                                        variant="contained"
                                        onClick={handleTriageModalClose}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Button
                                        disabled={loading}
                                        fullWidth={true}
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          formTriageRef?.current?.submitForm()
                                        }
                                      >
                                        Triage Case
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </>
                          </Formik>
                        </Paper>
                      </Modal>
                    </TableCell>
                  ) : null}
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_created_datetime
                      ? format(
                          parseISO(clientCase.client_case_created_datetime),
                          'M/d/Y h:mm a'
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {clientCase.client_case_status != 'Triage' ? (
            <>
              <Typography component="h2" variant="h1" mt={2} p={1}>
                Settings
              </Typography>
              <Formik
                initialValues={{
                  client_case_id: clientCase.client_case_id,
                  user_id: clientCase.user_id || '',
                  client_case_timing: clientCase.client_case_timing,
                  client_case_service_datetime:
                    clientCase.client_case_service_datetime,
                  client_case_denied_datetime:
                    clientCase.client_case_denied_datetime,
                  client_case_due_date: clientCase.client_case_due_date,
                  client_case_appeal_type: clientCase.client_case_appeal_type,
                  client_case_service_level:
                    clientCase.client_case_service_level,
                  client_case_determination:
                    clientCase.client_case_determination,
                  client_case_status: clientCase.client_case_status,
                }}
                // initialStatus={initialStatus}
                onSubmit={handleSubmit}
                innerRef={formikRef}
                validateOnChange
                validateOnChange={false}
              >
                <Stack mt={2} mb={2} p={1}>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'user_id',
                          display: 'User',
                          type: 'select',
                          fieldType: 'select',
                          options: users,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_timing',
                          display: 'Case Denial Timing',
                          type: 'select',
                          fieldType: 'select',
                          options: clientCaseTimings,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={4}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_service_datetime',
                          display: 'Service Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_denied_datetime',
                          display: 'Denial Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_due_date',
                          display: 'Case Due Date',
                          type: 'date',
                          fullWidth: true,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_appeal_type',
                          display: 'Appeal Type',
                          fieldType: 'select',
                          type: 'select',
                          options: clientCaseAppealTypes,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_determination',
                          display: 'Determination',
                          type: 'select',
                          fieldType: 'select',
                          options: clientCaseDeterminations,
                          InputProps: {
                            disabled: loading,
                          },
                          fullWidth: true,
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FieldGroup
                        fieldData={{
                          field: 'client_case_status',
                          display: 'Current Status',
                          fieldType: 'select',
                          type: 'select',
                          options: clientCaseStatuses,
                          InputProps: {
                            disabled: loading,
                          },
                          callback: () => {
                            if (!loading) {
                              formikRef.current.submitForm()
                            }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Formik>
              <sup>
                <i>
                  *Changing any of the fields above will automatically save
                  them, so be sure to only modify them if you really mean to do
                  it
                </i>
              </sup>
            </>
          ) : null}

          <Formik
            initialValues={{
              client_case_note_visibility: 'All',
              client_case_document_visibility: 'All',
            }}
            onSubmit={handleInfoSubmit}
            innerRef={formInfoRef}
          >
            <Stack mt={2} mb={2} p={1}>
              {formInfoRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formInfoRef?.current?.errors?.submit}
                </Alert>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={7}>
                  <FieldGroup
                    fieldData={{
                      field: 'client_case_note_content',
                      display: 'Leave Note',
                      type: 'text',
                      InputProps: {
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FieldGroup
                    fieldData={{
                      field: 'client_case_note_visibility',
                      display: 'Note & File Visibility',
                      type: 'select',
                      fieldType: 'select',
                      options: [
                        { value: 'All', display: 'All', selected: true },
                        { value: 'Internal', display: 'Internal' },
                      ],
                      InputProps: {
                        disabled: loading,
                      },
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    sx={{ marginBottom: openAttachDocuments ? '3px' : '0px' }}
                    variant="contained"
                    color={!openAttachDocuments ? 'primary' : 'error'}
                    onClick={() => {
                      setLoading(true)
                      if (openAttachDocuments) {
                        delete formInfoRef.current.values.files
                      }
                      setOpenAttachDocuments(
                        (openAttachDocuments) => !openAttachDocuments
                      )
                      setLoading(false)
                    }}
                  >
                    {!openAttachDocuments
                      ? 'Attach Documents'
                      : 'Cancel Attaching Documents'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={openAttachDocuments}>
                    <FieldGroup
                      fieldData={{
                        field: 'files',
                        display: '',
                        type: 'fileupload',
                        InputProps: {
                          disabled: loading || !openAttachDocuments,
                        },
                      }}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={10}></Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    disabled={loading}
                    fullWidth={true}
                    color="primary"
                    variant="contained"
                    onClick={() => formInfoRef?.current?.submitForm()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Formik>

          <hr />
          <Grid
            container
            spacing={3}
            style={{ maxHeight: '500px', overflow: 'auto' }}
            p={1}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                    {(clientCaseActionLogs || []).map((actionLog, i) => {
                      return (
                        <React.Fragment key={i}>
                          <TableRow sx={{ border: 0 }}>
                            <TableCell
                              sx={{
                                borderBottom: 2,
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              {actionLog.ActorType == user.type &&
                              actionLog.ActorID == user.id ? (
                                <Table p={0} m={0}>
                                  <TableBody>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        rowSpan={3}
                                        style={{
                                          background: actionLog.IsInternal
                                            ? '#66B6F9'
                                            : '#39A1F9',
                                          color: '#FFFFFF',
                                          borderRight: '1px solid black',
                                          borderBottom: 0,
                                          textAlign: 'right',
                                        }}
                                      >
                                        {actionLog.IsInternal
                                          ? 'Internal: '
                                          : null}
                                        {actionLog.Description}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: 'center',
                                        }}
                                        style={{
                                          width: 200,
                                          borderBottom: 0,
                                          fontWeight: 'bold',
                                          paddingBottom: 0,
                                          background: actionLog.IsInternal
                                            ? '#6CA2EB'
                                            : '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.Actor}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderBottom: 0,
                                          textAlign: 'center',
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          background: actionLog.IsInternal
                                            ? '#6CA2EB'
                                            : '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.ActorType}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          textAlign: 'center',
                                          paddingTop: 0,
                                          borderBottom: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          background: actionLog.IsInternal
                                            ? '#6CA2EB'
                                            : '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.Datetime}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ) : (
                                <Table key={i} p={0} m={0}>
                                  <TableBody>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          textAlign: 'center',
                                          paddingBottom: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          borderBottom: 0,
                                          fontWeight: 'bold',
                                          background:
                                            actionLog.ActorType === 'Customer'
                                              ? '#E9DBAC'
                                              : actionLog.IsInternal
                                              ? '#EFEFEF'
                                              : '#F3F3F3',
                                        }}
                                      >
                                        {actionLog.Actor}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          background:
                                            actionLog.ActorType === 'Customer'
                                              ? '#E9DBAC'
                                              : actionLog.IsInternal
                                              ? '#EFEFEF'
                                              : '#F3F3F3',
                                        }}
                                        rowSpan={3}
                                      >
                                        {actionLog.Description}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          borderBottom: 0,
                                          textAlign: 'center',
                                          background:
                                            actionLog.ActorType === 'Customer'
                                              ? '#E9DBAC'
                                              : actionLog.IsInternal
                                              ? '#EFEFEF'
                                              : '#F3F3F3',
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        style={{ width: 200 }}
                                      >
                                        {actionLog.ActorType}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          borderBottom: 0,
                                          textAlign: 'center',
                                          background:
                                            actionLog.ActorType === 'Customer'
                                              ? '#E9DBAC'
                                              : actionLog.IsInternal
                                              ? '#EFEFEF'
                                              : '#F3F3F3',
                                          paddingTop: 0,
                                        }}
                                        style={{ width: 200 }}
                                      >
                                        {actionLog.Datetime}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Typography component="h2" variant="h1" mt={4} p={1}>
            Documents
          </Typography>
          <Grid container spacing={3} p={1} mb={5}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <DataTable tableRows={clientCaseDocuments || []} />
              {!Array.isArray(clientCaseDocuments) ||
              clientCaseDocuments.length == 0 ? (
                <Typography component="i" variant="h3" p={1}>
                  No documents have been attached to this case.
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  ) : (
    <>
      <h1>Loading...</h1>
    </>
  )
}

export default CaseDetail
