import React, { useState, useEffect } from 'react'

import SingleTitleLayout from 'layouts/SingleTitle'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeCheckoutForm from 'components/diy/StripeCheckoutForm'
import axios from 'utils/axios'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

function DIYForm() {
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    axios
      .post('/stripe/createPaymentIntent', {
        items: ['diy-request'],
      })
      .then((res) => {
        setClientSecret(res?.data?.client_secret)
      })
  }, [])

  const appearance = {
    theme: 'stripe',
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <SingleTitleLayout title="DIY Form">
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <StripeCheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </SingleTitleLayout>
  )
}

export default DIYForm
