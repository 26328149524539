import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import resolveErrorText from 'helpers/resolveErrorText'

import useUser from 'hooks/useUser'
import useAuth from 'hooks/context/useAuth'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { useParams } from 'react-router-dom'

import { format, parseISO } from 'date-fns'
import UserCreateEditForm from 'components/users/UserCreateEditForm'

function UserDetail() {
  const { user } = useAuth()
  const { userGet, userClearMFA, userSetPasswordToTemp } = useUser()
  const { userId } = useParams()
  const [detailedUser, setDetailedUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  //const handleResetPassword = () =
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)
  const [clearMFAModalOpen, setClearMFAModalOpen] = useState(false)

  const handleOpenModal = (setModalFunction) => {
    setModalFunction(true)
  }
  const handleCloseModal = (setModalFunction) => {
    setModalFunction(false)
  }

  const handleResetUserPassword = async () => {
    try {
      setLoading(true)
      setResetPasswordModalOpen(false)
      await userSetPasswordToTemp(detailedUser.user_id)
      setError(false)
    } catch (e) {
      setError(resolveErrorText(e))
    } finally {
      setLoading(false)
    }
  }

  const handleClearMFASecret = async () => {
    try {
      setLoading(true)
      setClearMFAModalOpen(false)
      await userClearMFA(detailedUser.user_id)
      setError(false)
    } catch (e) {
      setError(resolveErrorText(e))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!detailedUser) {
      ;(async function fetchInitialData() {
        setLoading(true)
        try {
          let newUser = await userGet(userId)
          setDetailedUser(newUser)
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [])

  // console.log(user)

  return detailedUser ? (
    <Box>
      {error && <Alert severity="warning">{error}</Alert>}
      <Typography component="h1" variant="h1" mb={4}>
        User Details
      </Typography>
      <Dialog
        open={resetPasswordModalOpen}
        onClose={() => {
          handleCloseModal(setResetPasswordModalOpen)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset User's Password</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will reset this user's password and then email them with an
            updated, automatically generated password. Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseModal(setResetPasswordModalOpen)
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleResetUserPassword} autoFocus>
            Reset User Password
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={clearMFAModalOpen}
        onClose={() => {
          handleCloseModal(setClearMFAModalOpen)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Clear User's MFA Secret
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will clear out a user's MFA secret. They will need to readd it
            upon the next time they log in. This action cannot be undone. Do you
            wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseModal(setClearMFAModalOpen)
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleClearMFASecret} autoFocus>
            Clear User MFA Secret
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        {!user.isAdmin || detailedUser.user_is_admin ? (
          <Table
            sx={{ minWidth: 700, mb: 4, p: 1 }}
            aria-label="customized table"
          >
            <TableBody>
              <TableRow>
                <TableCell>User:</TableCell>
                <TableCell colSpan={2}>
                  {`${detailedUser.user_first_name} ${detailedUser.user_last_name} <${detailedUser.user_email}>`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Role:</TableCell>
                <TableCell colSpan={2}>Administrator</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Updated:</TableCell>
                <TableCell colSpan={2}>
                  {detailedUser.user_modified_datetime
                    ? format(
                        parseISO(detailedUser.user_modified_datetime),
                        'M/d/Y h:mm a'
                      )
                    : 'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created:</TableCell>
                <TableCell colSpan={2}>
                  {detailedUser.user_created_datetime
                    ? format(
                        parseISO(detailedUser.user_created_datetime),
                        'M/d/Y h:mm a'
                      )
                    : 'N/A'}
                </TableCell>
              </TableRow>
              {user.isAdmin ? (
                <TableRow>
                  <TableCell>Actions:</TableCell>
                  <TableCell>
                    <Button
                      disabled={loading}
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenModal(setResetPasswordModalOpen)}
                    >
                      Reset Password
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={loading}
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenModal(setClearMFAModalOpen)}
                    >
                      Clear MFA Secret
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        ) : detailedUser ? (
          <>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Email:</TableCell>
                  <TableCell colSpan={2}>{detailedUser.user_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Actions:</TableCell>
                  <TableCell>
                    <Button
                      disabled={loading}
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenModal(setResetPasswordModalOpen)}
                    >
                      Reset Password
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={loading}
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenModal(setClearMFAModalOpen)}
                    >
                      Clear MFA Secret
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography component="h2" variant="h1" mb={2}>
              User Edit
            </Typography>
            <UserCreateEditForm
              submitSuccessCallback={() => {
                alert('User has been updated successfully')
              }}
              user={detailedUser}
            />
          </>
        ) : null}
      </Paper>
    </Box>
  ) : null
}

export default UserDetail
