import axios from 'utils/axios'

const useCustomer = () => {
  const customerSearch = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/customer/search`, {
        params: searchTerms,
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  return {
    customerSearch: customerSearch,
  }
}

export default useCustomer
