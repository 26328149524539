import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import isValidToken from 'helpers/auth/isValidToken'

import useAuth from 'hooks/context/useAuth'

function AuthGuard({ children }) {
  const location = useLocation()
  let { token, challenge, isAuthenticated, isInitialized } = useAuth()

  if (!isInitialized) return <div />

  // if user unauthenticated, redirect to login
  if (!isValidToken(token)) {
    return <Navigate to="/console/user-login" replace />
  } else if (
    isAuthenticated &&
    location.pathname.startsWith('/console/user-login')
  ) {
    return <Navigate to="/console/dashboard" replace />
  }

  // if user logged in but requires 2FA, redirect to correct challenge
  if (
    challenge === 'MFA_AUTH' &&
    location.pathname !== '/console/user-login/challenge/auth-code'
  )
    return <Navigate to="/console/user-login/challenge/auth-code" replace />

  if (
    challenge === 'RESET_TEMP_PASSWORD' &&
    location.pathname !== '/console/user-login/challenge/reset-password'
  )
    return (
      <Navigate to="/console/user-login/challenge/reset-password" replace />
    )

  if (
    challenge === 'MFA_INITIALIZE' &&
    location.pathname !== '/console/user-login/challenge/mfa-initialize'
  )
    return (
      <Navigate to="/console/user-login/challenge/mfa-initialize" replace />
    )

  return <>{children}</>
}

export default AuthGuard
