import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'

import DataTable from 'components/common/DataTable'
import useClientCase from 'hooks/useClientCase'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import { format, parseISO } from 'date-fns'

import { useParams } from 'react-router-dom'

function CustomerCaseDetail() {
  const formInfoRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
  const { id } = useParams()
  const {
    clientCaseGet,
    clientCaseActionHistory,
    clientCaseNoteCreate,
    clientCaseDocumentList,
    clientCaseDocumentsUpload,
    clientCaseDocumentDownload,
  } = useClientCase()

  const [clientCaseActionLogs, setClientCaseActionLogs] = useState([])
  const [clientCaseDocuments, setClientCaseDocuments] = useState([])
  const [clientCase, setClientCase] = useState(null)

  const handleInfoSubmit = async (values, { setErrors }) => {
    setLoading(true)
    try {
      if (!values.client_case_note_content && !values.files) {
        throw new Error('Must contain note content OR files')
      }

      if (values.client_case_note_content) {
        await clientCaseNoteCreate(
          clientCase.client_case_id,
          null,
          values.client_case_note_content
        )
      }
      if (values.files) {
        await clientCaseDocumentsUpload(
          clientCase.client_case_id,
          values.files,
          null
        )
        await loadDocuments()
      }
      await loadActionHistory()
      formInfoRef.current.resetForm()
    } catch (err) {
      setErrors({
        submit:
          err.response?.data?.message ??
          err?.message ??
          'An error has occurred',
      })
    } finally {
      setLoading(false)
    }
  }

  const loadActionHistory = async () => {
    let dbActionLogs = await clientCaseActionHistory(id)
    let actionLogDisplay = []
    if (Array.isArray(dbActionLogs) && dbActionLogs.length > 0) {
      for (let i in dbActionLogs) {
        actionLogDisplay.push({
          ActionName: dbActionLogs[i].client_case_action_name,
          ActorID: dbActionLogs[i].client_case_action_actor_id,
          ActorType: dbActionLogs[i].client_case_action_actor_type,
          Actor: dbActionLogs[i].client_case_action_actor,
          Description: dbActionLogs[i].client_case_action_description,
          Datetime: format(
            parseISO(dbActionLogs[i].client_case_action_created_datetime),
            'M/d/Y h:mm a'
          ),
        })
      }
    }
    setClientCaseActionLogs(actionLogDisplay)
  }

  const loadDocuments = async () => {
    let dbDocs = await clientCaseDocumentList({
      clientCaseID: id,
      limit: 125,
    })
    let displayDocuments = []
    if (dbDocs?.client_case_documents?.length > 0) {
      for (let i in dbDocs.client_case_documents) {
        displayDocuments.push({
          'Uploaded By': dbDocs.client_case_documents[i].user
            ? `${dbDocs.client_case_documents[i].user.user_first_name} ${dbDocs.client_case_documents[i].user.user_last_name}`
            : dbDocs.client_case_documents[i].customer
            ? `${dbDocs.client_case_documents[i].customer.customer_first_name} ${dbDocs.client_case_documents[i].customer.customer_last_name}`
            : 'System',
          'File Name':
            dbDocs.client_case_documents[i].client_case_document_file_name,
          'Created Date + Time': format(
            parseISO(
              dbDocs.client_case_documents[i]
                .client_case_document_created_datetime
            ),
            'M/d/Y h:mm a'
          ),
          Download: dbDocs.client_case_documents[i]
            .client_case_document_cloud_available ? (
            <Button
              variant="contained"
              onClick={() => {
                clientCaseDocumentDownload(
                  dbDocs.client_case_documents[i].client_case_document_id
                )
              }}
            >
              Download
            </Button>
          ) : (
            'Archived'
          ),
        })
      }
    }
    setClientCaseDocuments(displayDocuments)
  }

  useEffect(() => {
    ;(async function fetchInitialData() {
      setLoading(true)
      try {
        let loadedClientCase = await clientCaseGet(id, true)

        await loadActionHistory()
        await loadDocuments()

        // set this very last
        setClientCase(loadedClientCase)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return clientCase ? (
    <>
      <Box>
        <Typography component="h1" variant="h1" mb={4}>
          Case Details
        </Typography>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 700, mb: 4, p: 1 }}
              aria-label="customized table"
            >
              <TableBody>
                <TableRow>
                  <TableCell>Assigned Consultant:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase?.user
                      ? `${clientCase?.user.user_first_name} ${clientCase.user.user_last_name}`
                      : 'Unassigned'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_status}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Due Date:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_due_date
                      ? format(
                          parseISO(clientCase.client_case_due_date),
                          'M/d/Y'
                        )
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Date:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_service_datetime
                      ? format(
                          parseISO(clientCase.client_case_service_datetime),
                          'M/d/Y'
                        )
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denial Date:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_denied_datetime
                      ? format(
                          parseISO(clientCase.client_case_denied_datetime),
                          'M/d/Y'
                        )
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Level:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_service_level}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Appeal Type:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_appeal_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Determination:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_determination}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_created_datetime
                      ? format(
                          parseISO(clientCase.client_case_created_datetime),
                          'M/d/Y h:mm a'
                        )
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Update:</TableCell>
                  <TableCell colSpan={2}>
                    {clientCase.client_case_modified_datetime
                      ? format(
                          parseISO(clientCase.client_case_modified_datetime),
                          'M/d/Y h:mm a'
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Formik
            initialValues={{
              client_case_note_visibility: 'All',
              client_case_document_visibility: 'All',
            }}
            onSubmit={handleInfoSubmit}
            innerRef={formInfoRef}
          >
            <Stack mt={2} mb={2} p={1}>
              {formInfoRef?.current?.errors?.submit && (
                <Alert severity="warning">
                  {formInfoRef?.current?.errors?.submit}
                </Alert>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={9}>
                  <FieldGroup
                    fieldData={{
                      field: 'client_case_note_content',
                      display: 'Leave Note',
                      type: 'text',
                      InputProps: {
                        disabled: loading,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    sx={{ marginBottom: openAttachDocuments ? '3px' : '0px' }}
                    variant="contained"
                    color={!openAttachDocuments ? 'primary' : 'error'}
                    fullWidth={true}
                    onClick={() => {
                      setLoading(true)
                      if (openAttachDocuments) {
                        delete formInfoRef.current.values.files
                      }
                      setOpenAttachDocuments(
                        (openAttachDocuments) => !openAttachDocuments
                      )
                      setLoading(false)
                    }}
                  >
                    {!openAttachDocuments
                      ? 'Attach Documents'
                      : 'Cancel Attaching Documents'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={openAttachDocuments}>
                    <FieldGroup
                      fieldData={{
                        field: 'files',
                        display: '',
                        type: 'fileupload',
                        InputProps: {
                          disabled: loading || !openAttachDocuments,
                        },
                      }}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12} sm={9}></Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    disabled={loading}
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                    onClick={() => formInfoRef?.current?.submitForm()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Formik>
          <Grid
            container
            spacing={3}
            style={{ maxHeight: '500px', overflow: 'auto' }}
            p={1}
          >
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                    {(clientCaseActionLogs || []).map((actionLog, i) => {
                      return (
                        <React.Fragment key={i}>
                          <TableRow sx={{ border: 0 }}>
                            <TableCell
                              sx={{
                                borderBottom: 2,
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              {actionLog.ActorType === 'Customer' ? (
                                <Table p={0} m={0}>
                                  <TableBody>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        rowSpan={3}
                                        style={{
                                          background: '#39A1F9',
                                          color: '#FFFFFF',
                                          borderRight: '1px solid black',
                                          borderBottom: 0,
                                          textAlign: 'right',
                                        }}
                                      >
                                        {actionLog.IsInternal
                                          ? 'Internal: '
                                          : null}
                                        {actionLog.Description}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: 'center',
                                        }}
                                        style={{
                                          width: 200,
                                          borderBottom: 0,
                                          fontWeight: 'bold',
                                          paddingBottom: 0,
                                          background: actionLog.IsInternal
                                            ? '#6CA2EB'
                                            : '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.Actor}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderBottom: 0,
                                          textAlign: 'center',
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          background: '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.ActorType}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          textAlign: 'center',
                                          paddingTop: 0,
                                          borderBottom: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          background: '#609BEB',
                                          color: '#FFFFFF',
                                        }}
                                      >
                                        {actionLog.Datetime}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ) : (
                                <Table key={i} p={0} m={0}>
                                  <TableBody>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          textAlign: 'center',
                                          paddingBottom: 0,
                                        }}
                                        style={{
                                          width: 200,
                                          borderBottom: 0,
                                          fontWeight: 'bold',
                                          background: '#E9DBAC',
                                        }}
                                      >
                                        {actionLog.Actor}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          background: '#E9DBAC',
                                        }}
                                        rowSpan={3}
                                      >
                                        {actionLog.Description}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          borderBottom: 0,
                                          textAlign: 'center',
                                          background: '#E9DBAC',
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        style={{ width: 200 }}
                                      >
                                        {actionLog.ActorType}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ border: 0 }}>
                                      <TableCell
                                        sx={{
                                          borderRight: 0.5,
                                          textAlign: 'center',
                                          background: '#E9DBAC',
                                          paddingTop: 0,
                                          borderBottom: 0,
                                        }}
                                        style={{ width: 200 }}
                                      >
                                        {actionLog.Datetime}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Typography component="h2" variant="h1" mt={4} p={1}>
            Documents
          </Typography>
          <Grid container spacing={3} p={1} mb={5}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <DataTable tableRows={clientCaseDocuments || []} />
              {!Array.isArray(clientCaseDocuments) ||
              clientCaseDocuments.length == 0 ? (
                <Typography component="i" variant="h3" p={1}>
                  No documents have been attached to this case.
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  ) : (
    <>
      <h1>Loading...</h1>
    </>
  )
}

export default CustomerCaseDetail
